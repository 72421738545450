export default defineI18nConfig(() => ({
  legacy: false,
  availableLocales: ['en-US', 'pt-BR', 'es'],
  numberFormats: {
    'en-EU': {
      currency: {
        style: 'currency',
        currency: 'EUR',
        notation: 'standard',
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
    },
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD',
        notation: 'standard',
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
    },
    'pt-BR': {
      currency: {
        style: 'currency',
        currency: 'BRL',
        notation: 'standard',
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
    },
    'es': {
      currency: {
        style: 'currency',
        currency: 'EUR',
        notation: 'standard',
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
    },
  },
  detectBrowserLanguage: {
    useCookie: true,
    cookieKey: 'i18n_redirected',
    redirectOn: 'root', // recommended
  },
  messages: {
    'en-US': {
      'Zomro__Windows-Server-2012-R2': 'Windows Server 2012 R2',
      'Zomro__Ubuntu-20.04-amd64-fromdisk': 'Ubuntu 20.04',
      'Zomro__CentOS-8-Stream-amd64-fromdisk': 'CentOS 8',
      'ISPsystem__Debian-11-x86_64': 'Debian 11',
      'server': {
        status: {
          pending: 'Pending',
          setting: 'Setting',
          working: 'Working',
          error: 'Error',
          deleted: 'Deleted',
          active: 'On',
        },
      },
      'stripe': {
        status: {
          active: 'Active',
          canceled: 'Canceled',
          incomplete: 'Incomplete',
        },
        interval: {
          month: 'Monthly',
          day: 'Daily',
          year: 'Yearly',
          week: 'Weekly',
        },
      },
      'welcome': 'Welcome',
      'hello': 'Hello',
      'categories': {
        vps: 'VPS',
        dedicated: 'Dedicated',
      },
      'products': {
        select_os: 'Select OS',
        title: 'Products',
        subtitle: 'Available products',
        empty: 'No products available',
        go: 'See products',
        buy: 'Buy',
      },

    },
    'pt-BR': {
      'Zomro__Windows-Server-2012-R2': 'Windows Server 2012 R2',
      'Zomro__Ubuntu-20.04-amd64-fromdisk': 'Ubuntu 20.04',
      'Zomro__CentOS-8-Stream-amd64-fromdisk': 'CentOS 8',
      'ISPsystem__Debian-11-x86_64': 'Debian 11',
      'server': {
        status: {
          pending: 'Pendente',
          setting: 'Configurando',
          working: 'Sincronizando',
          error: 'Erro',
          deleted: 'Deletado',
          active: 'On',
        },
      },
      'stripe': {
        status: {
          active: 'Ativo',
          canceled: 'Cancelado',
          incomplete: 'Incompleto',
        },
        interval: {
          month: 'Mensal',
          day: 'Diário',
          year: 'Anual',
          week: 'Semanal',
        },
      },
      'welcome': 'Bem-vindo',
      'hello': 'Olá',
      'categories': {
        vps: 'VPS',
        dedicated: 'Dedicado',
      },
      'products': {
        select_os: 'Selecione o SO',
        title: 'Produtos',
        subtitle: 'Produtos disponíveis',
        empty: 'Nenhum produto disponível',
        go: 'Ver produtos',
        buy: 'Comprar',
      },
    },
    'es': {
      'Zomro__Windows-Server-2012-R2': 'Windows Server 2012 R2',
      'Zomro__Ubuntu-20.04-amd64-fromdisk': 'Ubuntu 20.04',
      'Zomro__CentOS-8-Stream-amd64-fromdisk': 'CentOS 8',
      'ISPsystem__Debian-11-x86_64': 'Debian 11',
      'stripe': {
        status: {
          active: 'Activo',
          canceled: 'Cancelado',
          incomplete: 'Incompleto',
        },
        interval: {
          month: 'Mensual',
          day: 'Diario',
          year: 'Anual',
          week: 'Semanal',
        },
      },
      'welcome': 'Bienvenido',
      'hello': 'Hola',
      'categories': {
        vps: 'VPS',
        dedicated: 'Dedicado',
      },
      'products': {
        select_os: 'Seleccione el SO',
        title: 'Productos',
        subtitle: 'Productos disponibles',
        empty: 'Ningún producto disponible',
        go: 'Ver productos',
        buy: 'Comprar',
      },

    },
  },
}))
